// Flatly 4.0.0
// Bootswatch


// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css?family=Lato:400,700,400italic" !default;
@import url($web-font-path);

// Navbar =======================================================================

.bg-primary {
  .navbar-nav .active > .nav-link {
    color: $success !important;
  }
}

.bg-dark {
  background-color: $success !important;
  &.navbar-dark .navbar-nav {
    .nav-link:focus,
    .nav-link:hover,
    .active > .nav-link {
      color: $primary !important;
    }
  }
}

// Buttons =====================================================================

.btn {
  &-secondary,
  &-secondary:hover,
  &-warning,
  &-warning:hover {
    color: #fff;
  }
}

// Typography ==================================================================

// Tables ======================================================================

.table {

  .thead-dark th {
    background-color: $primary;
  }

  &-success,
  &-info,
  &-warning,
  &-danger {
    color: #fff;
  }

  &-success {
    &, > th, > td {
      background-color: $success;
    }
  }

  &-info {
    &, > th, > td {
      background-color: $info;
    }
  }

  &-danger {
    &, > th, > td {
      background-color: $danger;
    }
  }

  &-warning {
    &, > th, > td {
      background-color: $warning;
    }
  }

  &-hover {

    .table-success:hover {
      &, > th, > td {
        background-color: darken($success, 5%);
      }
    }

    .table-info:hover {
      &, > th, > td {
        background-color: darken($info, 5%);
      }
    }

    .table-danger:hover {
      &, > th, > td {
        background-color: darken($danger, 5%);
      }
    }

    .table-warning:hover {
      &, > th, > td {
        background-color: darken($warning, 5%);
      }
    }

  }
}

// Forms =======================================================================

// Navs ========================================================================

.nav-tabs {
  .nav-link.active,
  .nav-link.active:focus,
  .nav-link.active:hover,
  .nav-item.open .nav-link,
  .nav-item.open .nav-link:focus,
  .nav-item.open .nav-link:hover {
    color: $primary;
  }
}

.pagination {
  a:hover {
    text-decoration: none;
  }
}

// Indicators ==================================================================

.close {
  text-decoration: none;
  opacity: 0.4;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.badge {
  &-secondary,
  &-warning {
    color: #fff;
  }
}


.alert {
  border: none;
  color: $white;

  a,
  .alert-link {
    color: #fff;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      background-color: $value;
    }
  }
}

// Progress bars ===============================================================

// Containers ==================================================================
.modal .close{
  color: $black;
}
